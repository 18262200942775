import { type LinksFunction, type LoaderFunctionArgs, json } from '@remix-run/node';
import {
  Links,
  Meta,
  type MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import twStylesHreft from '~/tailwind.css?url';
import { cn } from './lib/utils';
import { NonFlashOfWrongThemeEls, ThemeProvider, useTheme } from './utils/theme-provider';
import { getThemeSession } from './utils/theme.server';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: twStylesHreft }];

export const meta: MetaFunction = () => {
  return [{ title: 'Cometa Admin' }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const themeSession = await getThemeSession(request);

  return json({
    ENV: {
      SENTRY_DSN: process.env.SENTRY_DSN,
    },
    theme: themeSession.getTheme(),
  });
}

function App() {
  const data = useLoaderData<typeof loader>();

  const [theme] = useTheme();

  return (
    <html lang="es" className={cn('antialiased dark:bg-gray-950', theme)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <NonFlashOfWrongThemeEls ssrTheme={Boolean(data.theme)} />
      </head>
      <body className="bg-white dark:bg-gray-950 text-gray-950 dark:text-white">
        <Outlet />
        <ScrollRestoration />
        <script
          // biome-ignore lint/security/noDangerouslySetInnerHtml: default remix setup
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();

  return (
    <ThemeProvider specifiedTheme={data.theme}>
      <App />
    </ThemeProvider>
  );
}

export default withSentry(AppWithProviders, { wrapWithErrorBoundary: true });

export const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return <div>Algo falló en la aplicación.</div>;
};
